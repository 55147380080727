import snippet from '@segment/snippet';

const renderSnippet = () => {
  const opts: snippet.Options = {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_JS_API_KEY,
    page: false,
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
};

export const Segment: React.FC = () => (
  <script
    id="segment-script"
    dangerouslySetInnerHTML={{
      __html: renderSnippet(),
    }}
  />
);
