'use client'; // Error boundaries must be Client Components

import { Segment } from 'components/scripts';
import { fontVariables } from 'utils/app-dependencies';
import { cn } from 'utils/tailwind';

import ErrorBoundaryPage, { type ErrorBoundaryPageProps } from './error';

export default function GlobalErrorBoundaryPage(props: ErrorBoundaryPageProps) {
  return (
    <html className={cn('min-h-dvh', fontVariables)} lang="en">
      <head>{!process.env.CI && <Segment />}</head>
      <body>
        <ErrorBoundaryPage {...props} />
      </body>
    </html>
  );
}
