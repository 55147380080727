import 'react-loading-skeleton/dist/skeleton.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'styles/gladly.css';
import 'styles/globals.css';

import { DM_Mono, Montserrat, Nunito } from 'next/font/google';

import { cn } from 'utils/tailwind';

export const montserrat = Montserrat({
  subsets: ['latin'],
  display: 'block',
  variable: '--font-heading',
  preload: true,
});

export const nunito = Nunito({
  subsets: ['latin'],
  display: 'block',
  variable: '--font-sans',
  preload: true,
});

export const dmMono = DM_Mono({
  weight: '500',
  subsets: ['latin'],
  display: 'block',
  variable: '--font-tag',
});

/**
 * CSS class names for font variables
 */
export const fontVariables = cn(
  nunito.variable,
  montserrat.variable,
  dmMono.variable
);
